import { render, staticRenderFns } from "./photo.image.vue?vue&type=template&id=515226d7&"
import script from "./photo.image.vue?vue&type=script&lang=js&"
export * from "./photo.image.vue?vue&type=script&lang=js&"
import style0 from "./photo.image.vue?vue&type=style&index=0&id=515226d7&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../common/temp/node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports